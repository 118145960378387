.fc-toolbar-title {
  color: rgb(126, 99, 171) !important;
  font-size: 1.2em !important;
}
.fc-timeline-slot-frame {
  justify-content: center !important;
}
.fc-timeline-event {
  border-radius: 5px;
}
.fc-header-toolbar {
  margin-bottom: 1em !important;
}
.fc-datagrid-cell-cushion {
  position: relative;
}
.fc-timeline-lane-frame {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.fc-timeline-slot-cushion {
  font-size: 12px;
  color: #616161;
  font-weight: 400;
}
.fc-datagrid-cell-main {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 85%;
  cursor: pointer;
  color: rgb(126, 99, 171);
}
.fc-button {
  background-color: white !important;
  color: rgba(126, 99, 171, 1) !important;
  border: rgba(126, 99, 171, 1) solid 1px !important;
}

.fc-scroller {
  margin-bottom: 0px !important;
  padding: 0px 7px;
}

.fc-scrollgrid-section-header .fc-scroller {
  overflow: hidden !important;
}

/* SCROLLBAR - START - */

/* width */
.fc-scroller::-webkit-scrollbar {
  width: 8px;
}

/* Handle on hover */
.fc-scroller::-webkit-scrollbar-thumb:hover {
  background: #103e62;
}

/* SCROLLBAR - END - */

.service-address {
  cursor: pointer;
  color: #c0c0c0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 85%;
}
.tooltip {
  position: absolute;
  right: 8px;
  bottom: 0;
}
.distance-wrapper {
  display: 'flex';
}
.tooltip-text {
  // color: rgba(126, 99, 171, 0.6);
  color: #aaa;
  padding: 5px;
}
.fc .fc-button-primary:disabled {
  visibility: hidden;
}
.event-tooltip {
  width: 100%;
  height: 22px;
}
.event-tooltip-btn {
  width: 100%;
  height: 22px;
}

@media screen and (max-width: 916px) {
  .fc-timeline-slot-cushion {
    font-size: 9px;
    padding: 4px;
  }
  .tooltip {
    display: none;
  }
}
